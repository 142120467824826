import React, { useContext } from 'react'
import { Card, CardContent } from './../utils/Card'
import { FormattedMessage } from "react-intl"
import { OvertimeContext } from './index'

export default function Overtime(props) {
	const { handleOvertimeChange } = useContext(OvertimeContext)

	const {
		id,
		name,
		hours
	} = props

	function handleChange(value) {
		let changes = { hours: parseFloat(value) }
		handleOvertimeChange(id, { ...props, ...changes })
	}

	return (
		<React.Fragment>
			<Card className={"card--white"}>
				<CardContent>
                    <h3><FormattedMessage id={"label." + name} /></h3>
                    <div className="form-group">
                        <label className="label-control" htmlFor={name}><FormattedMessage id="label.number_overtime" /></label>
                        <input
                            className="form-control"
                            id={name}
                            name="hours"
                            type="number"
                            min="0"
                            value={hours}
                            onChange={e => handleChange(e.target.value)}
                        />
                    </div>
                </CardContent>
			</Card>
		</React.Fragment>
	)
}