import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { CompareFormContext } from './index'

export default function Button({ amount }) {
    const { countryFrom, countryTo } = useContext(CompareFormContext)
    
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        setShowButton(amount > 0 ? true : false)
    }, [amount])

    let linkTo = (countryFrom !== undefined && countryTo !== undefined) &&
                        `/compare/${countryFrom.currency}/${countryFrom.value}/${countryTo.value}/${amount}`

	return (
        <>
            {showButton &&
                <Link className="btn btn--primary" to={linkTo}>
                    <FormattedMessage id="button.compare" />
                </Link>
            }
            {!showButton &&
                <button className="btn btn--primary" disabled="disabled">
                    <FormattedMessage id="button.compare" />
                </button>
            }
        </>
    )
}
