import React from 'react'
import Icon from './../utils/Icon'

export default function CompareSelect({ name, current, options, handleChange }) {
    const {
        value,
        label,
        flag,
    } = current

    const optionsElements = options.map(option => {
        return <option key={option.value} value={option.value}>{option.label}</option>
    })

    function handleSelectOnChange(changed) {
        const index = options.findIndex(o => o.value === changed)
        handleChange(options[index])
    }

    return (
        <div className="select-control">
            <span className="flag">
                <img src={flag} title={label} alt={label} />
            </span>
            <select
                name={name}
                value={value}
                onChange={(e) => handleSelectOnChange(e.target.value)}
            >
                {optionsElements}
            </select>
            <span className="text">
                {label}
            </span>
            <Icon icon={"arrow_down"} />
        </div>
    )
}