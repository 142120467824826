import React from 'react'
import { FormattedMessage } from "react-intl";
import Operator from './Operator';

function OperatorList({ operators }) {
	const isTableVisible = operators.length > 0 ? true : false

	return (
		<React.Fragment>
			{isTableVisible && <Table operators={operators} />}
			{!isTableVisible && <div className="no-results"><FormattedMessage id="no_results" /></div>}
		</React.Fragment>       			
	)
}

function Table({ operators }) {
	const operatorElements = operators.map(operator => {
		return <Operator key={operator.CompanyId} {...operator} />
	})
	return (
		<div className="table-scroll">
        	<table className="comparison-table">
        		<thead>
        			<tr>
        				<th><FormattedMessage id="label.operator" /></th>
        				<th><FormattedMessage id="label.receiving" /></th>
        				<th><FormattedMessage id="label.rate" /></th>
        				<th><FormattedMessage id="label.fee" /></th>
						<th><FormattedMessage id="label.type" /></th>
        				<th><FormattedMessage id="label.method" /></th>
        				<th><FormattedMessage id="label.speed" /></th>        				
        			</tr>
        		</thead>
        		<tbody>
        			{operatorElements}
        		</tbody>
        	</table>
        </div>
	)
}

export default OperatorList;
