import React, { useContext } from 'react'
import { FormattedMessage } from "react-intl";
import Income from './Income';
import Icon from '../utils/Icon'
import { BudgetContext } from './index'

function IncomeList() {
	const { handleIncomeAdd, incomes } = useContext(BudgetContext)

	const incomeElements = incomes.filter(income => income.primary === 0).map(income => {
		return <Income key={income.id} {...income} />
	})

	return (
		<React.Fragment>
      		{incomeElements}
      		<button className="btn btn--primary btn--icon" onClick={() => handleIncomeAdd()}>
                <Icon icon={"add"} width={20} height={20} /> <FormattedMessage id="button.add_income" />
            </button>
        </React.Fragment>
	)
}

export default IncomeList;
