import React, { useContext } from 'react'
import { FormattedMessage } from "react-intl";
import Expense from './Expense';
import Icon from '../utils/Icon'
import { BudgetContext } from './index'

function ExpenseList() {
	const { handleExpenseAdd, expenses } = useContext(BudgetContext)

	const expenseElements = expenses.map(expense => {
		return <Expense key={expense.id} {...expense} />
	})

	return (
		<React.Fragment>
      		{expenseElements}
      		<button className="btn btn--primary btn--icon" onClick={() => handleExpenseAdd()}>
                <Icon icon={"add"} width={20} height={20} /> <FormattedMessage id="button.add_expense" />
            </button>
        </React.Fragment>
	)
}

export default ExpenseList;
