import React, { useState, useEffect, createContext, useContext } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, FormattedNumber, FormattedDate } from "react-intl";
import TopNav from './../navigation/TopNav'
import TopHeader from './../navigation/TopHeader'
import Icon from './../utils/Icon'
import OperatorList from './OperatorList'
import MarketRate from './MarketRate'
import FilterList from './FilterList'
import Loader from './../utils/Loader'
import Legend from './Legend'
import BottomNavigation from './../navigation/BottomNavigation'
import { AppContext } from './../App'
import endpoint from '../../api'
import google from 'react-ga';
google.initialize('UA-151343945-1');
google.pageview(window.location.pathname + window.location.search);

export const CompareListContext = createContext()
const BACK_BUTTON_KEY = 'saverasia.back_button'
const OPERATORS_KEY = 'saverasia.operators'

function CompareList({ match }) {
	const { selectedLanguage } = useContext(AppContext)

	const [data, setData] = useState([])
	const [operatorsCopy, setOperatorsCopy] = useState([])
	const [operators, setOperators] = useState([])
	const [marketRate, setMarketRate] = useState({ rate: 0, currency: null })
	const [filters, setFilters] = useState([])
	const [legend, setLegend] = useState([])
	const [filtered, setFiltered] = useState([])
	const [isLoading, setIsLoading] = useState()

	const CompareListContextValue = {
		handleFilteredAdd,
		handleFilteredRemove,
		filters
	}

	useEffect(() => {
		setIsLoading(true)
		endpoint.get(`/${selectedLanguage.value}/quote/${match.params.countryFrom}/${match.params.countryTo}/${match.params.amount}`).then(response => {
			setData(response.data)
			setOperators(response.data.results)
			setOperatorsCopy(response.data.results)
			setMarketRate(response.data.market_rate)
			setFilters(response.data.filters)
			setLegend(response.data.legend)
			setIsLoading(false)
		})
		localStorage.setItem(BACK_BUTTON_KEY, `/compare/${match.params.currencyFrom}/${match.params.countryFrom}/${match.params.countryTo}/${match.params.amount}`)
	}, [])

	function handleFilteredAdd(filter) {
		const newFiltered = [...filtered]
		const index = newFiltered.findIndex(f => f.name === filter.name)
		newFiltered[index] = filter
		index !== -1 ? setFiltered(newFiltered) : setFiltered([...filtered, filter])
	}

	function handleFilteredRemove(name) {	
		setFiltered(filtered.filter(f => f.name !== name))
	}

	useEffect(() => {
		localStorage.setItem(OPERATORS_KEY, JSON.stringify(operators))
	}, [operators])

	useEffect(() => {
		let filteredOperators = operatorsCopy;

		filtered.map(filter => {
			switch (filter.type) {
				case 'sort' :
					filteredOperators = filteredOperators.sort((a, b) => {
						return (typeof a[filter.value] === 'string') ? a[filter.value] > b[filter.value] : a[filter.value] < b[filter.value]
					})
					break;
				case 'filter' :
					let found = []

					filter.fields.map(field => {
						filteredOperators.map((operator) => {
							if (Array.isArray(operator[field])) {
								if (operator[field].includes(filter.value)) {
									found.push(operator)
								}
							} else {
								if (operator[field] == filter.value) {
									found.push(operator)
								}
							}
						})
					})

					filteredOperators = [...new Set(found)];
					break;
				default:
					break;
			}
		})

		setOperators(filteredOperators)
	}, [filtered])

	return (
		<div className="screen-container pagination">
			<TopNav>
            	<Link to={'/compare'}>
                    <Icon icon={"back"} />
                </Link>
                <h1>
                	{match.params.countryFrom}
                    <Icon icon={"arrow_right"} />
                	{match.params.countryTo}
                    <Icon icon={"arrow_right"} />
                	<FormattedNumber
						value={match.params.amount}
						style="currency"
						currency={match.params.currencyFrom}
	    			/>
                </h1>
            </TopNav>
            {!isLoading && <TopHeader>
                <h2>
                	<FormattedMessage id="topheader.compare.list.h2" />
                </h2>
                <p>
                	<FormattedMessage id="topheader.compare.list.p" values={{ date_today: data.date_today, date_updated: data.date_updated }} />
                </p>
            </TopHeader>}
            <div className="screen-content">
				<div className="container">
					{isLoading && <Loader />}
					<CompareListContext.Provider value={CompareListContextValue}>
						{!isLoading && <FilterList filters={filters} />}
						{!isLoading && <MarketRate marketRate={marketRate} />}
						{!isLoading && <OperatorList operators={operators} />}					
						{!isLoading && <a href={data.view_operators} className="btn btn--primary" target="_blank"><FormattedMessage id="button.view_all_operators" /></a>}
					</CompareListContext.Provider>
				</div>
			</div>
			{!isLoading && <Legend legend={legend} />}
			<BottomNavigation />
		</div>
	)
}

export default CompareList;
