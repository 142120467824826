import React, { useState, useContext } from 'react'
import { OvertimeContext } from './index'
import SummaryPieChart from './SummaryPieChart'
import Summary from './Summary'
import SummaryTotal from './SummaryTotal'
import { Card, CardContent } from './../utils/Card'

function SummaryList() {
	const { income, overtime } = useContext(OvertimeContext)

	const [overtimeList] = useState(overtime.filter(item => item.hours !== 0))

	const [totalIncomes] = useState(() => {
		return {
			label: 'label.basic_wage',
			color: '#00AB00',
			amount: income.amount
		}
	});

	const [totalAmount] = useState(() => {
		return {
			label: 'label.total_income',
			color: '',
			amount: parseFloat(income.amount + getTotalOvertime())
		}
	});

	function getTotalOvertime() {
        let amount = 0

        overtime.map((item) => {
            amount += item.hours * item.penalty * income.hourly_rate
        })

        return amount
	}
	
	let summaryList = [ totalIncomes ]

	const overtimeSummaryElements = overtimeList.map(item => {
		let summary = {
			label: 'label.' + item.name,
			color: item.color,
			amount: parseFloat(item.hours * item.penalty * income.hourly_rate)
		}

		if (summary.amount > 0) {
			summaryList.push(summary)

			return <Summary key={item.id} {...summary} />
		}

		return false;
	})

	return (
		<Card className={"card--white"}>
            <SummaryPieChart
				list={summaryList}
            />
            <CardContent>
            	<div className="summary-table">
	      			<Summary {...totalIncomes} />
	      			{overtimeSummaryElements}
	      			<SummaryTotal {...totalAmount} />
	      		</div>
            </CardContent>
		</Card>
	)
}

export default SummaryList;
