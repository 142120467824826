import React from 'react';

export default function TopHeader({ children }) {
	return (
		<div className="top-header">
			<div className="container">
				{children}
			</div>
		</div>
	);
}