import React, { useContext } from 'react';
import TopNav from './../navigation/TopNav';
import { FormattedMessage } from "react-intl";
import Icon from './../utils/Icon';
import LanguageSelect from './../home/LanguageSelect'
import CurrencySelect from './../budget/CurrencySelect';
import { AppContext } from './../App'
import BottomNavigation from './../navigation/BottomNavigation';
import google from 'react-ga';
google.initialize('UA-151343945-1');
google.pageview(window.location.pathname + window.location.search);

export default function Settings() {
    const { languages } = useContext(AppContext)  

    const defaultLanguage = languages.find(() => true)
   
    return (
        <div className="screen-container">
            <TopNav>
                <h1><FormattedMessage id="topnav.setting.h1" /></h1>
            </TopNav>
            <div className="container setting">
                <div className="setting-row">
                    <Icon icon={"translate"} />
                    <div className="setting-label">
                        <FormattedMessage id="label.language" />
                        <small><FormattedMessage id="label.language.desc" /></small>
                    </div>
                    <div className="setting-action">
                        {defaultLanguage && <LanguageSelect size={""} name={"language"} />}
                    </div>
                </div>
                <div className="setting-row">
                    <Icon icon={"note"} />
                    <div className="setting-label">
                        <FormattedMessage id="label.currency" />
                        <small><FormattedMessage id="label.currency.desc" /></small>
                    </div>
                    <div className="setting-action">
                        <CurrencySelect />
                    </div>
                </div>
            </div>
            <BottomNavigation />
        </div>
    );
}