import React from 'react'
import { FormattedMessage, FormattedNumber } from "react-intl";
import { OvertimeContext } from './index'

export default function Summary(props) {
	const {
		label,
		color,
		amount
	} = props

	return (
		<div className="table-row">
            <div className="column">
                <span className="legend-table-icon" style={{backgroundColor: color, borderColor: color}}></span>
                <FormattedMessage id={label} />
            </div>
            <div className="column">
            	<FormattedNumber
					value={amount}
					style="currency"
    				currency="MYR"
    			/>
            </div>
        </div>
	)
}