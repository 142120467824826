import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from "react-intl";
import TopNav from './../navigation/TopNav';
import BottomNavigation from './../navigation/BottomNavigation';
import Loader from './../utils/Loader';
import Icon from '../utils/Icon'
import FilterIcon from '../compare/FilterIcon'
import { Link } from 'react-router-dom';
import endpoint from '../../api';
import { AppContext } from './../App'
import google from 'react-ga';
google.initialize('UA-151343945-1');
google.pageview(window.location.pathname + window.location.search);

const BACK_BUTTON_KEY = 'saverasia.back_button'
const OPERATORS_KEY = 'saverasia.operators'

export default function OperatorPage({ match }) {
    const { selectedLanguage } = useContext(AppContext)

    const [loading, setLoading] = useState()
    const [operator, setOperator] = useState([])
    const [content, setContent] = useState({ title: '', body: '' })

    useEffect(() => {
        var operatorsJSON = localStorage.getItem(OPERATORS_KEY)
        setOperator(JSON.parse(operatorsJSON).filter(operator => operator.CompanyLink === match.url))
    }, [])

    useEffect(() => {
        setLoading(true)
        endpoint.get(`${selectedLanguage.value}/operator/${match.params.slug}/`).then(response => {
            setLoading(false)
            setContent(response.data)
        })        
    }, [selectedLanguage.value, match.params.slug])

    const back_button = localStorage.getItem(BACK_BUTTON_KEY)

    return (
        <div className="screen-container">
            {loading ?
                <Loader />
                    :
                <>
                    <TopNav>
                        <Link to={back_button}>
                            <Icon icon={"back"} width={20} height={20} />
                        </Link>
                        <h1>{content.title}</h1>
                    </TopNav>
                    <div className="screen-content screen-content--white">
                        <div className="container text-container">
                            <div dangerouslySetInnerHTML={ {__html: content.body} } />
                            <Table operator={operator} />
                        </div>
                    </div>
                </>
            }
            <BottomNavigation />
        </div>
    );
}

function Table({ operator }) {
	const operatorElements = operator.map((operator, key) => {
        
        let TypeDeliveryFromElements = operator.TypeDeliveryFromPath.map((path, key) => {
            return <FilterIcon path={path} key={key} />
        })
    
        let TypeDeliveryToElements = operator.TypeDeliveryToPath.map((path, key) => {
            return <FilterIcon path={path} key={key} />
        })
    
        let TypeElements
    
        if (operator.TypePath.length > 0) {
            TypeElements = operator.TypePath.map((path, key) => {
                return <FilterIcon path={path} key={key} />
            })
        }

		return <tr key={key}>
                    <td>
                        <div className="type-icon">
                            {TypeElements}
                        </div>
                    </td>
                    <td>
                        <div className="type-icon">
                            {TypeDeliveryFromElements}
                            {TypeDeliveryToElements &&
                                <>
                                    <Icon icon={"arrow_right"} />
                                    {TypeDeliveryToElements}
                                </>
                            }
                        </div>
                    </td>
                    <td>
                        <div className="type-icon">
                            <FilterIcon path={operator.TimeDeliveryPath} />
                        </div>
                    </td>
                </tr>
    })
    
	return (
        <table>
            <thead>
                <tr>
                    <th><FormattedMessage id="label.type" /></th>
                    <th><FormattedMessage id="label.method" /></th>
                    <th><FormattedMessage id="label.speed" /></th>
                </tr>
            </thead>
            <tbody>
                {operatorElements}
            </tbody>
        </table>        
	)
}