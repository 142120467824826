export default {
	EN: {
		"carousel.item1.h2": "Save Time",
		"carousel.item2.h2": "Save Money",
		"carousel.item3.h2": "Save for your Future",
		"carousel.item1.p": "Save time by quickly comparing money transfer providers – to help you find the best price and product when you send money home",
		"carousel.item2.p": "Learn how to save money by comparing money transfer providers and using the SaverAsia calculators",
		"carousel.item3.p": "Learn how to save for your future and improve your finances through our finance basics guide",
		"button.start": "Start",
		"topnav.compare.form.h1": "Compare Rates",
		"topheader.compare.form.h2": "Save on money transfers",
		"topheader.compare.form.p": "Compare costs of companies offering money transfer services",
		"label.country_from": "Send money from",
		"label.country_to": "Send to",
		"label.how_much": "How much",
		"button.compare": "Compare rates now",
		"topheader.compare.list.h2": "Comparison Results",
		"topheader.compare.list.p": "Green dot results are live as of today, {date_today}. Red dot results were collected via mystery shopping on {date_updated}. These results may not include all services available for this remittance corridor. The below information includes all known costs.",
		"topnav.resource.h1": "Finance Basics",
		"topnav.calculators.h1": "Calculators",
		"card1.calculators.h3": "Overtime Pay Calculator",
		"card1.calculators.p": "If you are working in Malaysia you can use this calculator to work out how much overtime pay you should receive.",
		"card2.calculators.h3": "Budget Calculator",
		"card2.calculators.p": "Add your income and your expenses to see how much you could save and to make sure you aren’t overspending.",
		"breadcrumbs.step": "Step {step} of {count}",
		"topnav.overtime.h1": "Overtime Calculator",
		"topheader.overtime.h2": "Malaysian Overtime Pay Calculator",
		"topheader.overtime.p": "This calculator will work out the amount of overtime you should receive based on the hours you have worked and the standard government over time requirements.",
		"label.hourly_rate": "Your hourly rate is ",
		"label.overtime_hours.h4": "Overtime Hours",
		"label.overtime_hours.p": "As per Employment Act 1955 (Malaysia), the employee shall be paid at a rate not less than 1.5 times his/her hourly rate for overtime work in excess of the normal work hours. For work on a rest day the pay shall be no less than 2.0 times the hourly rate, and on a public holiday no less than 3.0 times the hourly rate.",
		"label.basic_wage": "Basic wage",
		"label.pay_period": "Pay period",
		"label.amount": "Amount",
		"label.standard_days": "Normal Days (x1.5)",
		"label.rest_days": "Rest Days (x2)",
		"label.public_holidays": "Public Holidays (x3)",
		"label.paid_public_holidays": "Paid Public Holidays (x2)",
		"label.unpaid_public_holidays": "Unpaid Public Holidays (x3)",
		"label.overtime": "Overtime",
		"label.total_expense": "Total Expenses",
		"label.total_income": "Total Income",
		"label.money_left": "Money left over",
		"label.daily": "Daily",
		"label.weekly": "Weekly",
		"label.monthly": "Monthly",
		"label.item": "Item",
		"label.primary_income": "Primary Income",
		"label.market_rate": "The mid market FX is {market_rate} for live data results",
		"label.operator": "Operator",
		"label.type": "Form of Transfer",
		"label.receiving": "Receiving",
		"label.rate": "FX rate",
		"label.fee": "Fee",
		"label.method": "Method of transfer",
		"label.speed": "Speed of transfer",
		"label.number_overtime": "Number of hours worked overtime",
		"topnav.faq.h1": "FAQ",
		"bottom.faq.p": "SaverAsia has the answers to most of your questions but if you need more information, please contact us at <a>saverasia@developingmarkets.com</a> and we will try to help you.",
		"notfound.h2": "\"Oh - Uh\"",
		"notfound.p": "Sorry, we can't find the screen you are looking for. Use the navigation below.",	
		"topheader.summary.h2": "Summary",
		"topheader.summary.p": "Below you can see how much money you should receive for this period.",
		"no_results": "No results containing all your filter terms were found.",
		"button.back": "Back",
		"button.prev": "Prev",
		"button.next": "Next",
		"button.remove": "Remove",
		"button.add_income": "Add income",
		"button.add_expense": "Add expense",
		"button.read_more": "Read more",
		"button.show_less": "Show less",
		"topnav.budget.h1": "Budget Calculator",
		"topheader.income.h2": "Income",
		"topheader.income.p": "Let's start with entering all the ways you earn money.",
		"topheader.expense.h2": "Expenses",
		"topheader.expense.p": "Add your expenses below. E.g. rent, food, remittances, bills (phone, electricity etc.) and travel.",
		"topheader.budget_summary.p": "Well done on completing your budget plan, let’s see how much money you’ve saved.",
		"card.other_income.h4": "Other Income",
		"card.other_income.p": "Do you earn any other income? E.g. government benefits, second job, rental income, driving a Grab, shares, etc.",
		"topnav.setting.h1": "Settings",
		"label.language": "Language",
		"label.currency": "Currency",
		"label.push_notification": "Push Notifications",
		"label.language.desc": "Select the app language",
		"label.currency.desc": "Select the app currency",
		"label.push_notification.desc": "Allow app to push notification",
		"label.not_supported": "Not supported",
		"button.view_all_operators": "View all operators",
		"logo.supported.h3": "Supported by",
		"logo.managed.h3": "Managed by",
		"label.view_legend": "View legend",
		"label.legend": "Legend",
		"menu.about" : "About",
		"menu.privacy_policy" : "Privacy Policy",
		"menu.terms_and_conditions" : "Terms and Conditions",
		"menu.disclaimer" : "Disclaimer",
		"label.january": "January",
		"label.february": "‌February",
		"label.march": "March",
		"label.april": "April",
		"label.may": "‌May",
		"label.june": "June",
		"label.july": "July",
		"label.august": "August",
		"label.september": "September",
		"label.october": "‌October",
		"label.november": "November",
		"label.december": "December",
		"error.primary_income": "The primary income is required",
		"error.basic_wage": "The basic wage is required",
		"button.apply": "Apply"
	},
	ID: {
		"carousel.item1.h2": "Menghemat waktu",
		"carousel.item2.h2": "Menghemat Uang",
		"carousel.item3.h2": "Menghemat/Menabung untuk Masa Depan Anda",
		"carousel.item1.p": "Hemat waktu dengan membandingkan secara cepat penyedia jasa transfer uang - untuk membantu Anda menemukan harga dan produk terbaik saat mengirimkan uang ke rumah",
		"carousel.item2.p": "Pelajari bagaimana cara menghemat uang dengan membandingkan penyedia jasa transfer uang dan menggunakan kalkulator SaverAsia",
		"carousel.item3.p": "Pelajari bagaimana cara menghemat untuk masa depan Anda dan meningkatkan keuangan Anda melalui panduan dasar keuangan kami",
		"button.start": "Mulakan",
		"topnav.compare.form.h1": "Perbandingan Tarif",
		"topheader.compare.form.h2": "Menghemat sewaktu mentransfer uang",
		"topheader.compare.form.p": "Bandingkan biaya dari masing-masing perusahaan yang menawarkan layanan transfer uang",
		"label.country_from": "Mengirim uang dari",
		"label.country_to": "Dikirim ke",
		"label.how_much": "Berapa banyak",
		"button.compare": "Bandingkan tarif sekarang",
		"topheader.compare.list.h2": "Hasil Perbandingan",
		"topheader.compare.list.p": "Hasil waktu nyata ditunjukkan oleh titik hijau termasuk data yang terkumpul hari ini {date_today}, sementara hasil statis ditunjukkan oleh titik merah yang diperbarui pada {date_updated}. Hasil perbandingan ini mungkin tidak termasuk semua layanan yang tersedia untuk koridor pengiriman uang. Informasi di bawah mencakup semua biaya yang diketahui.",
		"topnav.resource.h1": "Dasar-dasar keuangan",
		"topnav.calculators.h1": "Kalkulator",
		"card1.calculators.h3": "Kalkulator Overtime",
		"card1.calculators.p": "Sekiranya anda bekerja di Malaysia, anda boleh menggunakan kalkulator ini untuk mengetahui berapa banyak bayaran gaji yang perlu anda terima.",
		"card2.calculators.h3": "Kalkulator Anggaran",
		"card2.calculators.p": "Tambah pendapatan dan perbelanjaan anda untuk melihat berapa banyak yang anda boleh simpan dan pastikan anda tidak melabur",
		"breadcrumbs.step": "Langkah {step} dari {count}",
		"topnav.overtime.h1": "Kalkulator Overtime",
		"topheader.overtime.h2": "Kalkulator Pembayaran Lembur Malaysia",
		"topheader.overtime.p": "Kalkulator ini akan menghitung jumlah lembur yang seharusnya Anda terima berdasarkan jam kerja Anda dan persyaratan lembur berdesarkan standar pemerintah.",
		"label.hourly_rate": "Tarif per jam Anda adalah ",
		"label.overtime_hours.h4": "Jam lembur",
		"label.overtime_hours.p": "Sesuai Undang-Undang Ketenagakerjaan 1955 (Malaysia), karyawan harus dibayar dengan tarif tidak kurang dari 1,5 kali tarif per jam untuk kerja lembur melebihi jam kerja normal. Untuk bekerja pada hari diluar jadwal kerja, upahnya harus tidak kurang dari 2,0 kali tarif per jam, dan pada hari libur umum tidak kurang dari 3,0 kali tarif per jam.",
		"label.basic_wage": "Upah Dasar",
		"label.pay_period": "Periode Pembayaran",
		"label.amount": "Jumlah",
		"label.standard_days": "Hari Standar (x1.5)",
		"label.rest_days": "Hari Istirahat (x2)",
		"label.paid_public_holidays": "Libur Nasional Berbayar (x2)",
		"label.unpaid_public_holidays": "Libur Nasional Tidak Berbayar (x3)",
		"label.overtime": "Lembur",
		"label.total_expense": "Total Biaya",
		"label.total_income": "Total Pendapatan",
		"label.money_left": "Uang yang tersisa",
		"label.daily": "Setiap hari",
		"label.weekly": "Setiap minggu",
		"label.monthly": "Bulanan",
		"label.item": "Butir/Nomor Urut",
		"label.primary_income": "Pendapatan/Penghasilan Utama",
		"label.market_rate": "FX pasar menengah adalah {market_rate}",
		"label.operator": "Operator",
		"label.type": "Formulir transfer",
		"label.receiving": "Diterima",
		"label.rate": "Tarif",
		"label.fee": "Biaya",
		"label.method": "Metode transfer",
		"label.speed": "Kecepatan transfer",
		"label.number_overtime": "Jumlah jam kerja lembur",
		"topnav.faq.h1": "Pertanyaan yang Sering Diajukan",
		"bottom.faq.p": "SaverAsia memiliki jawaban untuk sebagian besar pertanyaan Anda tetapi jika Anda memerlukan informasi lebih lanjut, silakan hubungi kami di <a>saverasia@developingmarkets.com</a> dan kami akan mencoba membantu Anda.",
		"notfound.h2": "\"Oh - Uh\"",
		"notfound.p": "Maaf, kami tidak dapat menemukan layar yang Anda cari. Gunakan navigasi di bawah ini.",
		"topheader.summary.h2": "Ringkasan",
		"topheader.summary.p": "Di bawah ini Anda dapat melihat berapa banyak uang yang seharusnya Anda terima untuk periode ini.",
		"button.back": "Kembali",
		"button.prev": "Kembali",
		"button.next": "Selanjutnya",
		"button.remove": "Menghapus",
		"button.add_income": "Tambahkan Penghasilan",
		"button.add_expense": "Tambahkan/Masukkan jumlah pengeluaran/biaya-biaya",
		"button.read_more": "Baca lebih lajut",
		"button.show_less": "Tampilkan lebih sedikit",
		"topnav.budget.h1": "Kalkulator Anggaran",
		"topheader.income.h2": "Pendapatan/Penghasilan",
		"topheader.income.p": "Mari kita mulai dengan memasukkan semua informasi bagaiman cara Anda menghasilkan/mendapatkan uang.",
		"topheader.expense.h2": "Pengeluaran/Biaya-biaya",
		"topheader.expense.p": "Tambahkan/Masukkan jumlah pengeluaran Anda di bawah ini. Cobalah untuk mengingat semua yang perlu Anda bayar termasuk kartu kredit, pinjaman, dan tagihan.",
		"topheader.budget_summary.p": "Selamat, Anda telah menyelesaikan rencana anggaran Anda, mari kita lihat berapa banyak uang yang dapat Anda tabung.",
		"card.other_income.h4": "Pendapatan/Penghasilan lainnya",
		"card.other_income.p": "Apakah Anda mendapatkan penghasilan lainnya? Misalnya: tunjangan pemerintah, pekerjaan sampingan, pendapatan sewa, mengendarai Grab, saham, dll.",
		"topnav.setting.h1": "Pengaturan",
		"label.language": "Bahasa",
		"label.currency": "Mata uang",
		"label.push_notification": "Pemberitahuan Push",
		"label.language.desc": "Pilih bahasa di aplikasi",
		"label.currency.desc": "Pilih mata uang di aplikasi",
		"label.push_notification.desc": "Izinkan aplikasi mendorong pemberitahuan",
		"label.not_supported": "Tidak didukung",
		"button.view_all_operators": "Lihat semua operator",
		"logo.supported.h3": "Didukung oleh",
		"logo.managed.h3": "Dikelola oleh",
		"label.view_legend": "Lihat riwayat",
		"label.legend": "Riwayat",
		"menu.about" : "Tentang",
		"menu.privacy_policy" : "Kebijakan pribadi",
		"menu.terms_and_conditions" : "Syarat dan ketentuan",
		"menu.disclaimer" : "Penolakan",
		"label.january": "Januari",
		"label.february": "Februari",
		"label.march": "Maret",
		"label.april": "April",
		"label.may": "Mei",
		"label.june": "Juni",
		"label.july": "Juli",
		"label.august": "Agustus",
		"label.september": "September",
		"label.october": "Oktober",
		"label.november": "November",
		"label.december": "Desember",
		"error.primary_income": "Penghasilan utama diperlukan",
		"error.basic_wage": "Upah dasar diperlukan",
		"button.apply": "Menerapkan"
	},
	KM: {
		"carousel.item1.h2": "សន្សំពេលវេលា",
		"carousel.item2.h2": "សន្សំប្រាក់",
		"carousel.item3.h2": "សន្សំដើម្បីអនាគតរបស់អ្នក",
		"carousel.item1.p": "សន្សំពេលវេលាដោយប្រៀបធៀបអ្នកផ្តល់សេវាផ្ទេរប្រាក់  - ដើម្បីជួយអ្នកស្វែងរកតម្លៃ និងផលិតផលល្អបំផុត នៅពេលដែលអ្នកផ្ញើប្រាក់មកផ្ទះ",
		"carousel.item2.p": "រៀនសន្សំប្រាក់ដោយប្រៀបធៀបអ្នកផ្ទេរប្រាក់ និងប្រើប្រាស់កម្មវិធីគណនា SaverAsia",
		"carousel.item3.p": "រៀនពីរបៀបសន្សំសម្រាប់អនាគតរបស់អ្នក និងធ្វើឱ្យប្រសើរឡើងនូវហិរញ្ញវត្ថុរបស់អ្នកតាមការណែនាំងាយៗផ្នែកហិរញ្ញវត្ថុរបស់យើង",
		"button.start": "ចាប់ផ្តើមពីឥលូវនេះ",
		"topnav.compare.form.h1": "អត្រាប្រៀបធៀប",
		"topheader.compare.form.h2": "សន្សំពីអ្នកផ្ទេរប្រាក់",
		"topheader.compare.form.p": "ប្រៀបធៀបតម្លៃនៃក្រុមហ៊ុនដែលផ្តល់សេវាកម្មផ្ទេរប្រាក់",
		"label.country_from": "ផ្ញើប្រាក់ពី",
		"label.country_to": "ផ្ញើទៅ",
		"label.how_much": "ចំនួន",
		"button.compare": "ប្រៀបធៀបអត្រាបច្ចុប្បន្ន",
		"topheader.compare.list.h2": "លទ្ធផលប្រៀបធៀប",
		"topheader.compare.list.p": "លទ្ធផលជាក់ស្តែងដែលចង្អុលបង្ហាញដោយចំណុចពណ៌បៃតងរួមមានទិន្នន័យដែលប្រមូលបាននៅថ្ងៃនេះ {date_today} ខណៈពេលដែលលទ្ធផលដែលមិនប្រែប្រួលបានបង្ហាញដោយចំណុចក្រហមដែលបានធ្វើបច្ចុប្បន្នភាពនៅ {date_updated} ។ លទ្ធផលទាំងនេះអាចមិនរាប់បញ្ចូលសេវាកម្មទាំងអស់ដែលមានសម្រាប់ការផ្ទេរប្រាក់ឆ្លងប្រទេស។ ព័ត៌មានខាងក្រោមរួមបញ្ចូលទាំងការចំណាយដែលបានដឹង។",
		"topnav.resource.h1": "មូលដ្ឋានហិរញ្ញវត្ថុ",
		"topnav.calculators.h1": "កម្មវិធីគណនា",
		"card1.calculators.h3": "កម្មវិធីគណនាប្រាក់បន្ថែមម៉ោង",
		"card1.calculators.p": "ប្រសិនបើអ្នកកំពុងធ្វើការនៅម៉ាឡេស៊ី អ្នកអាចប្រើប្រាស់កម្មវិធីគណនានេះដើម្បីរកប្រាក់បន្ថែមម៉ោងដែលអ្នកគួរទទួល",
		"card2.calculators.h3": "កម្មវិធីគណនាថវិកា",
		"card2.calculators.p": "បន្ថែមចំណូល និងចំណាយរបស់អ្នក ដើម្បីមើលឃើញថាចំនួនទឹកប្រាក់ប៉ុន្មានដែលអ្នកអាចសន្សំ និងដើម្បីប្រាកដថាអ្នកមិនចាយលុយលើស",
		"breadcrumbs.step": "ជំហាន {step} លើ {count}",
		"topnav.overtime.h1": "កម្មវិធីគណនាប្រាក់បន្ថែមម៉ោង",
		"topheader.overtime.h2": "កម្មវិធីគណនាប្រាក់បន្ថែមម៉ោងម៉ាឡេស៊ី",
		"topheader.overtime.p": "កម្មវិធីគណនានេះ នឹងគណនានូវចំនួនប្រាប់បន្ថែមម៉ោងដែលអ្នកគួរទទួលបានផ្អែកលើម៉ោងធ្វើការដែលអ្នកបានបំពេញ និងលក្ខខណ្ឌសម្រាប់ការងារបន្ថែមម៉ោងតាមការកំណត់របស់រដ្ឋាភិបាល",
		"label.hourly_rate": "អត្រាថ្លៃឈ្នួលក្នុងមួយម៉ោងរបស់អ្នកគឺ ",
		"label.overtime_hours.h4": "ម៉ោងធ្វើការបន្ថែម",
		"label.overtime_hours.p": "យោងតាមច្បាប់ស្តីពីការងារឆ្នាំ ១៩៥៥ (ប្រទេសម៉ាឡេស៊ី) និយោជិតត្រូវទទួលបានអត្រាប្រាក់ឈ្នួលមិនតិចជាង ១,៥ ដងក្នុងមួយម៉ោងរបស់គាត់ចំពោះការងារបន្ថែមម៉ោងក្នុងករណីលើសម៉ោងធ្វើការធម្មតា។ សម្រាប់ការងារនៅថ្ងៃសម្រាក ប្រាក់ឈ្នួលមិនត្រូវតិចជាង ២ ដងនៃអត្រាប្រាក់ឈ្នួលក្នុងមួយម៉ោងទេ ហើយនៅថ្ងៃសម្រាកសាធារណៈមិនតិចជាង ៣ ដងនៃអត្រាប្រាក់ឈ្នួលក្នុងមួយម៉ោងទេ។",
		"label.basic_wage": "ប្រាក់ឈ្នួលគោល",
		"label.pay_period": "រយៈពេលធ្វើការ",
		"label.amount": "ចំនួន",
		"label.standard_days": "ថ្ងៃធម្មតា (x១,៥)",
		"label.rest_days": "ថ្ងៃសម្រាក (x២)",
		"label.paid_public_holidays": "ថ្ងៃឈប់សម្រាក​ដោយទទួលបាន​ប្រាក់ឈ្នួល​",
		"label.unpaid_public_holidays": "ថ្ងៃឈប់​សម្រាក​ដោយមិនទទួលបានប្រាក់ឈ្នួល​",
		"label.overtime": "ម៉ោងបន្ថែម",
		"label.total_expense": "ចំណាយ​សរុប​",
		"label.total_income": "ចំណូលសរុប",
		"label.money_left": "ប្រាក់នៅសល់",
		"label.daily": "ប្រចាំថ្ចៃ",
		"label.weekly": "ប្រចាំសប្តាហ៍",
		"label.monthly": "ប្រចាំខែ",
		"label.item": "មុខបញ្ជី",
		"label.primary_income": "ចំណូលបឋម",
		"label.market_rate": "អត្រាប្តូរប្រាក់លើទីផ្សារជាមធ្យមគឺ {market_rate}",
		"label.operator": "ប្រតិបត្តិករ",
		"label.type": "ទម្រង់នៃការ​ផ្ទេរ​ប្រាក់​",
		"label.receiving": "ការទទួល",
		"label.rate": "អត្រាប្តូរប្រាក់",
		"label.fee": "ថ្លៃសេវា",
		"label.method": "វិធីសាស្ត្រផ្ទេរប្រាក់",
		"label.speed": "ល្បឿននៃការផ្ទេរប្រាក់",
		"label.number_overtime": "ចំនួនម៉ោងដែលបានធ្វើការបន្ថែមម៉ោង",
		"topnav.faq.h1": "សំណួរ​ដែលត្រូវ​បាន​សួរជា​ញឹកញាប់​",
		"bottom.faq.p": "​SaverAsia អាច​ស្រាយចម្ងល់​ភាគច្រើនរបស់អ្នក ប៉ុន្តែប្រសិនបើ​អ្នកត្រូវការ​ព័ត៌មានបន្ថែម​ សូមទាក់ទង​មកយើង​ខ្ញុំតាមរយៈ<a>saverasia@developingmarkets.com</a> ហើយ​យើង​នឹងខិតខំជួយដល់អ្នក។",
		"notfound.h2": "\"Oh - Uh\"",
		"notfound.p": "សូមទោស! យើង​រកមិនឃើញផ្ទាំងបង្ហាញដែលអ្នកកំពុង​ស្វែងរក​ទេ។ សូមប្រើប្រាស់សញ្ញាស្វែងរក (navigation) ខាងក្រោម។",
		"topheader.summary.h2": "សង្ខេប",
		"topheader.summary.p": "ខាងក្រោមនេះអ្នកអាចដឹងថាតើអ្នកគួរទទួលបានប្រាក់ប៉ុន្មានសម្រាប់រយៈពេលនេះ។",
		"no_results": "No results containing all your filter terms were found.", // To translate
		"button.back": "ចេញ",
		"button.prev": "Prev", // To translate
		"button.next": "បន្ទាប់",
		"button.remove": "ដកចេញ",
		"button.add_income": "បន្ថែមចំណូល",
		"button.add_expense": "បន្ថែមចំណាយ",
		"button.read_more": "អានបន្ថែម",
		"button.show_less": "បង្រួម",
		"topnav.budget.h1": "កម្មវិធីគណនាថវិកា",
		"topheader.income.h2": "ចំណូល",
		"topheader.income.p": "ចាប់ផ្តើមដោយការដាក់បញ្ចូលគ្រប់វិធីដែលអ្នករកប្រាក់បាន។",
		"topheader.expense.h2": "ចំណាយ",
		"topheader.expense.p": "បញ្ចូលចំណាយរបស់អ្នកខាងក្រោម។ ព្យាយាមគិតនូវអ្វីដែលត្រូវចាយវាយ រាប់បញ្ចូលទាំង កាតឥណទាន កម្ចី និងវិក័យបត្រផ្សេងៗ។",
		"topheader.budget_summary.p": "ពិតជាល្អណាស់សម្រាប់ការបំពេញផែនការថវិការបស់អ្នក តោះមើលចំនួនប្រាក់ដែលអ្នកសន្សំបាន",
		"card.other_income.h4": "ចំណូលផ្សេងៗ",
		"card.other_income.p": "តើអ្នកមានចំណូលផ្សេងឬទេ? ឧទាហរណ៍ តាវកាលិករបស់រដ្ឋាភិបាល ការងារបន្ទាប់បន្សំ ចំណូលពីការជួល បើកបរ Grab ភាគហ៊ុន ។ល។",
		"topnav.setting.h1": "ការបង្កើត",
		"label.language": "ភាសា",
		"label.currency": "រូបិយប័ណ្ណ",
		"label.push_notification": "ចុចលើកាជូនដំណឹង",
		"label.language.desc": "ជ្រើសរើសកម្មវិធីភាសា",
		"label.currency.desc": "ជ្រើសរើសកម្មវិធីរូបិយប័ណ្ណ",
		"label.push_notification.desc": "អនុញ្ញាត្តកម្មវិធីធ្វើកាជូនដំណឹង",
		"label.not_supported": "មិនត្រូវបានគាំទ្រ​",
		"button.view_all_operators": "មើល​ប្រតិបត្តិករទាំងអស់​",
		"logo.supported.h3": "គាំទ្រ​ដោយ",
		"logo.managed.h3": "គ្រប់គ្រង​ដោយ​",
		"label.view_legend": "មើល​ប្រវត្តិ",
		"label.legend": "ប្រវត្តិ",
		"menu.about" : "ស្តីពី​",
		"menu.privacy_policy" : "គោលនយោបាយ​​​ឯកជនភាព​",
		"menu.terms_and_conditions" : "លក្ខខណ្ឌ​",
		"menu.disclaimer" : "ការបដិសេធការ​ទទួលខុសត្រូវ​",
		"label.january": "មករា",
		"label.february": "កុម្ភៈ",
		"label.march": "មីនា",
		"label.april": "មេសា",
		"label.may": "ឧសភា",
		"label.june": "មិថុនា",
		"label.july": "កក្កដា",
		"label.august": "សីហា",
		"label.september": "កញ្ញា",
		"label.october": "តុលា",
		"label.november": "វិច្ឆិកា",
		"label.december": "ធ្នូ",
		"error.primary_income": "ចាំបាច់ត្រូវ​មាន​ប្រាក់​ចំណូល​បឋម​",
		"error.basic_wage": "ចាំបាច់ត្រូវមាន​ប្រាក់ឈ្នួលគោល​",
		"button.apply": "សម្រេច​យក​"
	},
	MY: {
		"carousel.item1.h2": "အချိန်ကုန်သက်သာခြင်း",
		"carousel.item2.h2": "‌ငွေကြေးစုဆောင်းခြင်း",
		"carousel.item3.h2": "အနာဂတ်အတွက် ရင်းနှီးမြှုပ်နှံခြင်း",
		"carousel.item1.p": "အိမ်သို့ငွေလွှဲပို့ရာတွင် ငွေလဲနှုန်းအများဆုံးပေးသည့် ငွေလွှဲဝန်ဆောင်မှုများကို အချိန်တိုအတွင်း နှိုင်းယှဉ်ဖေါ်ပြပေးနိုင်ခြင်းကြောင့် အချိန်ကုန်သက်သာစေသည်။",
		"carousel.item2.p": "‌ငွေလွှဲဝန်ဆောင်မှုပေးသူများကို နှိုင်းယှဉ်ခြင်းနှင့် SaverAsia ဂဏာန်းတွက်စက်ကို အသုံးပြုခြင်းများအားဖြင့် မည်သို့ ငွေကြေးစုဆောင်းရမလဲဆိုသည်ကို သင်ယူနိုင်ပေသည်။",
		"carousel.item3.p": "‌ကျွန်ုပ်တို့၏ အခြေခံငွေကြေးဆိုင်ရာ လမ်းညွှန်ချက်များကို အသုံးပြုခြင်းအားဖြင့် သင်၏ အနာဂတ်အတွက် ရင်းနှီးမြှုပ်နှံ နိုင်မည့် နည်းလမ်းများကို သိရှိလာနိုင်ပြီး သင်၏ ငွေကြေးစီမံမှုများ တိုးတက်လာမည် ဖြစ်သည်",
		"button.start": "စတင်ရန်",
		"topnav.compare.form.h1": "နှုန်းထားများ နှိုင်းယှဉ်ခြင်း",
		"topheader.compare.form.h2": "သက်သာသောနှုန်းဖြင့် ငွေလွှဲခြင်းများ",
		"topheader.compare.form.p": "ငွေလွှဲဝန်ဆောင်မှုများအတွက် ကုမ္ပဏီများ၏ ဝန်ဆောင်ခများကို နှိုင်းယှဉ်ခြင်း",
		"label.country_from": "‌ငွေလွှဲမည့်နိုင်ငံ",
		"label.country_to": "‌ငွေလက်ခံမည့်နိုင်ငံ",
		"label.how_much": "လွှဲပို့ချင်သည့် ပမာဏ",
		"button.compare": "နှုန်းထားများကို နှိုင်းယှဉ်ကြည့်ခြင်း",
		"topheader.compare.list.h2": "ရလဒ်များကို နှို်းယှဉ်ခြင်း",
		"topheader.compare.list.p": "အစိမ်းရောင်အစက်သည် ယနေ့ {date_today} ဖေါ်ပြချက် ရလဒ်များဖြစ်သည်။ အနီရောင်အစက်သည် {date_updated} ဈေးဝယ်ယူမှုများမှ ကောက်ယူဖေါ်ပြထားသော ရလဒ်များဖြစ်သည်။ ဤရလဒ်များသည် ဤငွေလွှဲမှုလမ်းကြောင်းအတွက် ရရှိနိုင်သော ဝန်ဆောင်မှုများအားလုံး မပါဝင်ပေ။ အောက်ပါအချက်များတွင် ကုန်ကျစရိတ်များအားလုံးပါဝင်ပေသည်။",
		"topnav.resource.h1": "အခြေခံငွေကြေးပညာ",
		"topnav.calculators.h1": "ဂဏာန်းတွက်စက်များ",
		"card1.calculators.h3": "အချိန်ပိုလုပ်အားခ တွက်ချက်ခြင်းအတွက် ဂဏာန်းတွက်စက်များ",
		"card1.calculators.p": "သင် မလေးရှားတွင် အလုပ်လုပ်ကိုင်နေပါက သင် ရရှိသင့်သည့် အချိန်ပိုလုပ်အားခကို သိရှိရန် ဤဂဏာန်းတွက်စက်ကို အသုံးပြု၍ တွက်ချက်နိုင်သည်",
		"card2.calculators.h3": "ဘတ်ဂျက် ဂဏာန်းတွက်စက်",
		"card2.calculators.p": "သင်ငွေမည်မျှစုဆောင်းနိုင်မည်ကို သိရှိရန်နှင့် ငွေအပိုမသုံးမိစေရန် သင်၏ ဝင်ငွေနှင့် အသုံးစရိတ်များကို ဖြည့်သွင်းလိုက်ပါ။",
		"breadcrumbs.step": "Step {step} of {count}",
		"topnav.overtime.h1": "အချိန်ပို ဂဏာန်းတွက်စက်",
		"topheader.overtime.h2": "မလေးရှားနိုင်ငံအတွက် အချိန်ပိုလုပ်အားခ ဂဏာန်းတွက်စက်",
		"topheader.overtime.p": "ဤဂဏာန်းတွက်စက်သည် သင်လုပ်ကိုင်ခဲ့ရသည့် အချိန်ပို နာရီအလိုက် ရရှိသင့်သည့် လုပ်အားခ ပမာဏကို အစိုးရက သတ်မှတ်ထားသည့် အချိန်ပိုစံနှုန်းသတ်မှတ်ချက်အလိုက် တွက်ချက်ပေးခြင်းဖြစ်သည်။ (အချိန်ပို ဟုတ်ရဲ့လား)",
		"label.hourly_rate": "သင်၏ နာရီအလိုက် လုပ်အားခနှုန်း ",
		"label.overtime_hours.h4": "အချိန်ပို အလုပ်ချိန်",
		"label.overtime_hours.p": "အလုပ်အကိုင်အက်ဉပဒေ ၁၉၉၅ (မလေးရှား) အရ အလုပ်သမားသည် ပုံမှန်အလုပ်ချိန်ထက် ကျော်လွန်ပြီး အချိန်ပိုအလုပ်လုပ်ကိုင်ရပါက ပုံမှန်နာရီအလိုက်ရရှိသော လုပ်ခလစာနှုန်း၏ ၁.၅ ဆ ကို အချိန်ပိုအလုပ်ချိန်အတွက် ရရှိရမည်ဖြစ်သည်။ နားရက်တွင် အချိန်ပိုကို နာရီအလိုက်လုပ်ခလစာ၏ ၂ ဆ ပေးရမည်ဖြစ်ပြီး အများပြည်သူအားလပ်ရက်တွင် အလုပ်လုပ်ကိုင်ပါက နာရီအလိုက်လုပ်ခလစာ၏ ၃ ဆပေးရမည်ဖြစ်သည်။  ",
		"label.basic_wage": "အခြေခံလုပ်ခလစာ",
		"label.pay_period": "ရရှိသည့် ကာလအပိုင်းအခြား",
		"label.amount": "ပမာဏ",
		"label.standard_days": "ပုံမှန်အလုပ်လုပ်ကိုင်ရသောရက်များ (x ၁.၅)",
		"label.rest_days": "နားရက်များ (x ၂)",
		"label.public_holidays": "အများပြည်သူအားလပ်ရက်များ (x ၃)",
		"label.paid_public_holidays": "လုပ်ခရသော အများပြည်သူအားလပ်ရက်",
		"label.unpaid_public_holidays": "လုပ်ခမရသော အများပြည်သူအားလပ်ရက်",
		"label.overtime": "အချိန်ပို",
		"label.total_expense": "စုစုပေါင်းကုန်ကျငွေ",
		"label.total_income": "စုစုပေါင်း ဝင်ငွေ",
		"label.money_left": "လက်ကျန်ငွေ",
		"label.daily": "‌နေ့စဉ်",
		"label.weekly": "အပတ်စဉ်",
		"label.monthly": "လစဉ်",
		"label.item": "အကြောင်းအရာ",
		"label.primary_income": "ပင်မဝင်ငွေ",
		"label.market_rate": "FX ၏ အလယ်အလတ်ဈေးကွက် {market_rate}",
		"label.operator": "ဝန်ဆောင်မှုပေးသူ",
		"label.type": "‌လွှဲပို့မှုပုံစံ",
		"label.receiving": "လက်ခံရရှိသူ",
		"label.rate": "FX  နှုန်း",
		"label.fee": "အခ",
		"label.method": "လွှဲပို့မှုနည်းလမ်း",
		"label.speed": "‌ပြောင်းလဲမှုနှုန်း",
		"label.number_overtime": "အချိန်ပိုလုပ်ကိုင်ရသော နာရီ",
		"topnav.faq.h1": "အမေးများသောမေးခွန်းများ",
		"bottom.faq.p": "သင်၏ မေးခွန်းများအတွက် SaverAsia တွင် အဖြေရှိသည်။ အချက်အလက်များ ပိုမိုသိရှိလိုပါက <a>saverasia@developingmarkets.com</a> သို့ ဆက်သွယ်လိုက်ပါ။ ကျွန်ုပ်တို့ သင့်ကို ကူညီဖြေကြားပေးပါမည်။",		
		"notfound.h2": "\"Oh - Uh\"",
		"notfound.p": "သင်ရှာဖွေနေသော စာမျက်နှာကို ရှာမတွေ့ပါ။ အောက်ဖေါ်ပြပါ လမ်းညွန်မှုအတိုင်း အသုံးပြုပါ။",	
		"topheader.summary.h2": "စုစုပေါင်း",
		"topheader.summary.p": "‌အောက်တွင် ဤကာလအတွက် သင်မည်မျှရရှိရမည်ကို ကြည့်ပါ။",
		"no_results": "No results containing all your filter terms were found.",
		"button.back": "‌နောက်သို့",
		"button.prev": "‌နောက်သို့",
		"button.next": "‌နောက်တဆင့်",
		"button.remove": "ဖယ်ရှားရန်",
		"button.add_income": "ဝင်ငွေများ ထပ်ထည့်ရန်",
		"button.add_expense": "Add expense",
		"button.read_more": "ပိုမိုသိရှိရန်",
		"button.show_less": "အကျဉ်းချုံ့ပြရန်",
		"topnav.budget.h1": "ဘတ်ဂျက် ဂဏာန်းတွက်စက်",
		"topheader.income.h2": "ဝင်ငွေ",
		"topheader.income.p": "သင်၏ ဝင်ငွေရလမ်းများအားလုံးကို ဖြည့်ကြည့်ကြစို့",
		"topheader.expense.h2": "အသုံးစရိတ်များ",
		"topheader.expense.p": "‌အောက်တွင် သင်၏ အသုံးစရိတ်များကို ဖြည့်ပါ။ အကြွေးဝယ်ကတ်သုံးခြင်း၊ ချေးငွေများ၊ ပေးစရာများနှင့် အသုံးစရိတ်များအားလုံးကို ဖြည့်သွင်းပေးရမည်",
		"topheader.budget_summary.p": "သင်၏ ဘတ်ဂျက်အစီအစဉ် အောင်မြင်ပါသည်။ သင် ငွေမည်မျှစုဆောင်းနိုင်မည်ကို ကြည့်ကြပါစို့",
		"card.other_income.h4": "အခြားဝင်ငွေ",
		"card.other_income.p": "သင့်တွင် အခြားဝင်ငွေများ ရှိပါသလား။ ဉပမာ - အစိုးရထံမှ အကျိုးခံစားခွင့်များ၊ ဒုတိယဝင်ငွေရအလုပ်၊ အိမ်ငှားရမ်းခ၊ Grab မောင်းနှင်မှုမှ၊ ရှယ်ယာဝယ်ယူမှုမှ အစရှိသဖြင့်",
		"topnav.setting.h1": "ပြင်ဆင်ရန်",
		"label.language": "ဘာသာစကား",
		"label.currency": "‌ငွေကြေးအမျိုးအစား",
		"label.push_notification": "သတိပေးမှုများကို နှိပ်ပါ",
		"label.language.desc": "ဘာသာစကားရွေးချယ်ပါ",
		"label.currency.desc": "ငွေကြေးအမျိုးအစားကို ရွေးချယ်ပါ",
		"label.push_notification.desc": "အက်မှ သတိပေးမှုများကို ခွင့်ပြုပါသည်",
		"label.not_supported": "မပံ့ပိုးပါ",
		"button.view_all_operators": "၀န်ဆောင်မှုပေးသူများအားလုံးကို ကြည့်ရန်",
		"logo.supported.h3": "ပံ့ပိုးပေးသူများမှာ",
		"logo.managed.h3": "ကြီးကြပ်စီမံသူများမှာ",
		"label.view_legend": "ငွေလွှဲကန့်သတ်ချက် ကြည့်ရန်",
		"label.legend": "ငွေလွှဲကန့်သတ်ချက်",
		"menu.about": "အကြောင်းအရာ",
		"menu.privacy_policy": "ပုဂ္ဂိုလ်ရေးလွတ်လပ်ခွင့် မူဝါဒ",
		"menu.terms_and_conditions": "သတ်မှတ်ချက်များ",
		"menu.disclaimer": "ငြင်းဆိုချက်",
		"label.january": "ဇန်နဝါရီလ",
		"label.february": "‌ဖေဖေါ်ဝါရီလ",
		"label.march": "မတ်လ",
		"label.april": "ဧပြီလ",
		"label.may": "‌မေလ",
		"label.june": "ဇွန်လ",
		"label.july": "ဇူလိုင်လ",
		"label.august": "ဩဂုတ်လ",
		"label.september": "စက်တင်ဘာလ",
		"label.october": "‌အောက်တိုဘာလ",
		"label.november": "နိုဝင်ဘာလ",
		"label.december": "ဒီဇင်ဘာလ",
		"error.primary_income": "အဓိကဝင်ငွေ လိုအပ်သည်",
		"error.basic_wage": "အခြေခံဝင်ငွေ လိုအပ်သည်",
		"button.apply": "လျှောက်ထားပါ`"
	},
};
