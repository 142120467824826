import React from 'react';
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import Icon from './../utils/Icon';

export default function BottomNavigation() {
	return (
		<nav className="bottom-navigation">
			<div className="container">
				<NavLink activeClassName="active" to="/compare">
					<Icon icon={"compare"} />
				</NavLink>
				<NavLink activeClassName="active" to="/page/why-compare">
					<Icon icon={"why_compare"} />
				</NavLink>
				<NavLink activeClassName="active" to="/resource">
					<Icon icon={"finance"} />
				</NavLink>
				<NavLink activeClassName="active" to="/calculator">
					<Icon icon={"calculator"} />
				</NavLink>
				<NavLink activeClassName="active" to="/help">
					<Icon icon={"question_mark"} />
				</NavLink>
			</div>
		</nav>
	);
}