import React, { useState} from 'react'
import Icon from './../utils/Icon'
import FilterIcon from './FilterIcon'
import { FormattedMessage } from "react-intl";

export default function Legend({ legend }) {
    const [isOpen, setIsOpen] = useState(false)
    
	const classIsOpen = isOpen === true ? "is-open" : ""
	
    const listElements = legend.map((item, key) => {
		return <li key={key}>
					<ul>
						<li><h3>{item.name}</h3></li>
						{item.list.map((i, k) => {
							return <li key={k}>
								<div className="type-icon">
									{i.path.map((p, pk) => {
										if (p === 'separator') {
											return <Icon icon={"arrow_right"} key={pk} />
										} else {
											return <FilterIcon path={p} key={pk} />
										}
									})}
								</div>
								<span>{i.label}</span>
							</li>
						})}
					</ul>
				</li>
    })
    
	return (
		<React.Fragment>
			<nav className="bottom-pagination bottom-pagination--center">
                <div className="container">
                    <button onClick={() => setIsOpen(true)}><FormattedMessage id="label.view_legend" /></button>
                </div>
            </nav>
            <div className={`select-modal ${classIsOpen}`}>
				<div className="select-modal--content">
					<ul className="select-modal--list">
						<li>
							<h2><FormattedMessage id="label.legend" /></h2>
							<button onClick={() => setIsOpen(false)}>
								<Icon icon={"clear"} />
							</button>
						</li>
						<li>
							<ul>
								{listElements}
							</ul>
						</li>
					</ul>
				</div>		
			</div>
		</React.Fragment>		
	)
}