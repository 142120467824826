import React from 'react'
import Icon from './../utils/Icon'
import FilterIcon from './FilterIcon'
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FormattedNumber } from "react-intl";

export default function Operator(props) {
	const {
		CompanyName,
		CompanyLink,
		CompanyLogo,
		CurrencyTo,
		TypePath,
		TypeDeliveryFromPath,
		TypeDeliveryToPath,
		TimeDeliveryPath,
		AmountTo,
		Fee,
		FeeCurrency,
		Rate,
		Live
	} = props

	const TypeDeliveryFromElements = TypeDeliveryFromPath.map((path, key) => {
		return <FilterIcon path={path} key={key} />
	})

	const TypeDeliveryToElements = TypeDeliveryToPath.map((path, key) => {
		return <FilterIcon path={path} key={key} />
	})

	let TypeElements

	if (TypePath.length > 0) {
		TypeElements = TypePath.map((path, key) => {
			return <FilterIcon path={path} key={key} />
		})
	}
	
	let External = CompanyLink.includes("http") ? true : false

	const CompanyLive = Live ? <span className="live"></span> : <span className="offline"></span>
	
	const CompanyImage = CompanyLogo ? <img src={CompanyLogo} title={CompanyName} alt={CompanyName} className="operator-logo--img" /> : CompanyName

	return (
		<tr>
			<th>
				{!External &&
					<Link to={CompanyLink} className="operator-logo">
						{CompanyLive}
						{CompanyLogo ? CompanyImage : CompanyName}<Icon icon={"arrow_right"} />
					</Link>
				}
				{External &&
					<a href={CompanyLink} className="operator-logo" target="_blank" rel="noopener noreferrer">
						{CompanyLive}
						{CompanyLogo ? CompanyImage : CompanyName}<Icon icon={"arrow_right"} />
					</a>
				}
			</th>
			<td>
				<strong>
					<FormattedNumber
						value={AmountTo}
						style="currency"
						currency={CurrencyTo}
						minimumFractionDigits={2}
						maximumFractionDigits={2}
					/>
				</strong>
			</td>
			<td>
				<FormattedNumber
					value={Rate}
					style={'decimal'}
					minimumFractionDigits={4}
					maximumFractionDigits={4}
    			/>
			</td>
			<td>				
				<FormattedNumber
					value={Fee}
					style="currency"
					currency={FeeCurrency}
					minimumFractionDigits={2}
					maximumFractionDigits={2}
    			/>
			</td>
			<td>
				<div className="type-icon">
					{TypeElements}
				</div>
			</td>
			<td>
				<div className="type-icon">
					{TypeDeliveryFromElements}
					{TypeDeliveryToElements &&
						<>
							<Icon icon={"arrow_right"} />
							{TypeDeliveryToElements}
						</>
					}
				</div>
			</td>
			<td>
				<div className="type-icon">
					<FilterIcon path={TimeDeliveryPath} />
				</div>
			</td>
		</tr>
	)
}