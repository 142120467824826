import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

function SummaryList(props) {
    const {
        totalExpenses,
        totalAmount
    } = props

    const data = [
        { value: totalExpenses.amount },
        { value: Math.abs(totalAmount.amount) }
    ]

    return (
        <div className="card-chart">
            <ResponsiveContainer>
                <PieChart width={250} height={250}>
                    <Pie data={data}
                        dataKey="value"
                        cy={130}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={115}
                        outerRadius={130}
                        paddingAngle={5}>
                        <Cell fill={totalExpenses.color} />
                        <Cell fill={totalAmount.color} />
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default SummaryList;
