import React from 'react';
import { FormattedMessage } from "react-intl";
import BottomNavigation from './../navigation/BottomNavigation';
import google from 'react-ga';
google.initialize('UA-151343945-1');
google.pageview(window.location.pathname + window.location.search);

class NotFound extends React.Component {
    render() {
        return (
            <div className="screen-container screen-not-found">
                <div className="screen-content">
                    <div className="not-found">
                        <h2><FormattedMessage id="notfound.h2" /></h2>
                        <p><FormattedMessage id="notfound.p" /></p>
                    </div>
                </div>
                <BottomNavigation />
            </div>
        );
    }
}

export default NotFound;