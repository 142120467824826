import React from 'react'
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function Button() {
	return (
		<Link
		    className="btn btn--white btn--large"
		    to="/compare"
		>
		    <FormattedMessage id="button.start" />
		</Link>
	)
}
