import React, { useState } from 'react';
import Icon from './../utils/Icon';
import Menu from './Menu';
import { BrowserRouter as Router, Link } from "react-router-dom";

export default function TopNav({ children }) {
	const [isOpen, setIsOpen] = useState(false)

    const openMenu = e => {
    	e.preventDefault()

    	setIsOpen(true)
    }

    const closeMenu = e => {
    	e.preventDefault()

    	setIsOpen(false)
    }

	const classIsOpen = isOpen === true ? " is-open" : ""

	return (
		<div className="top-navigation">
			<div className="container">
				<div className="navigation-left">
					{children}
				</div>
				<div className="navigation-right">
					<ul>
						<li>
							<Link to={"/settings"}><Icon icon={"setting"} /></Link>
						</li>
						<li>
							<a href="!#" onClick={openMenu}><Icon icon={"menu"} /></a>
							<Menu isOpen={isOpen} />
						</li>
					</ul>
				</div>
			</div>
			<div className={"menu-root" + classIsOpen} onClick={closeMenu}></div>
		</div>
	);
}