import React from 'react';

const icons = {
	back          : 'M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z',
	forward       : 'M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z',
	note          : 'M2.4,5C1.9,5,1.5,5.4,1.5,5.9v9.2c0,0.5,0.4,0.9,0.9,0.9h16.2c0.5,0,0.9-0.4,0.9-0.9V5.9c0-0.5-0.4-0.9-0.9-0.9 L2.4,5z M4.1,6.8h12.8c0.1,0.4,0.4,0.7,0.8,0.8v5.7c-0.4,0.1-0.7,0.4-0.8,0.8H4.1c-0.1-0.4-0.4-0.7-0.8-0.8V7.7 C3.7,7.5,4,7.2,4.1,6.8z M10.5,7.7C9,7.7,7.8,9,7.8,10.5s1.2,2.8,2.7,2.8s2.7-1.2,2.7-2.8S12,7.7,10.5,7.7z M6,9.6 c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9C6.9,10,6.5,9.6,6,9.6z M15,9.6c-0.5,0-0.9,0.4-0.9,0.9 c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9C15.9,10,15.5,9.6,15,9.6z',
	menu          : 'M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z',
	setting       : 'M19.5,12c0-0.6-0.1-1.1-0.1-1.6l2.8-1.8l-2.8-5.2l-2.8,1.8c-0.8-0.7-1.7-1.3-2.6-1.6V0H8.3v3.5 c-1,0.4-1.9,0.9-2.6,1.6L2.8,3.4L0,8.6l2.8,1.8c-0.1,0.5-0.1,1.1-0.1,1.6c0,0.6,0.1,1.1,0.1,1.6L0,15.4l2.8,5.2l2.8-1.8 c0.8,0.7,1.7,1.3,2.6,1.6V24h5.6v-3.5c1-0.4,1.9-0.9,2.6-1.6l2.8,1.8l2.8-5.2l-2.8-1.8C19.4,13.1,19.5,12.6,19.5,12z M20.2,16 l-1.4,2.6l-1.6-1L16.4,17l-0.8,0.7c-0.6,0.6-1.4,1.1-2.2,1.4l-0.9,0.4v1.1v2H9.7v-2v-1.1l-0.9-0.4c-0.8-0.3-1.6-0.8-2.2-1.4L5.8,17 l-0.9,0.5l-1.6,1L1.9,16l1.6-1l0.9-0.5l-0.2-1c-0.1-0.5-0.1-0.9-0.1-1.4s0-0.9,0.1-1.4l0.2-1L3.5,9.1l-1.6-1l1.4-2.6l1.6,1L5.8,7 l0.8-0.7c0.7-0.6,1.4-1.1,2.2-1.4l0.9-0.4V3.5v-2h2.8v2v1.1l0.9,0.4c0.8,0.3,1.6,0.8,2.2,1.4L16.4,7l0.9-0.5l1.6-1L20.2,8l-1.6,1 l-0.9,0.5l0.2,1c0.1,0.5,0.1,0.9,0.1,1.4s0,0.9-0.1,1.4l-0.2,1l0.9,0.5L20.2,16z M11.1,9c1.5,0,2.8,1.3,2.8,3c0,1.7-1.3,3-2.8,3 c-1.5,0-2.8-1.3-2.8-3C8.3,10.3,9.5,9,11.1,9 M11.1,7.5c-2.3,0-4.2,2-4.2,4.5s1.9,4.5,4.2,4.5s4.2-2,4.2-4.5S13.4,7.5,11.1,7.5 L11.1,7.5z',
	question_mark : 'M11.2,15.3c0.2-1.3,1-2,1.9-2.8c0.9-0.8,1.8-1.6,1.8-3c0-1.6-1.3-2.9-2.9-2.9c-1.5,0-2.7,1.1-2.9,2.5H7.5 C7.7,6.8,9.7,5,12,5c2.5,0,4.5,2,4.5,4.5c0,1.5-1,2.5-2,3.5c-0.8,0.7-1.5,1.4-1.7,2.3H11.2z M11.2,19h1.6v-1.6h-1.6V19z M12,1.6 C6.3,1.6,1.6,6.3,1.6,12S6.3,22.4,12,22.4c5.7,0,10.4-4.7,10.4-10.4S17.7,1.6,12,1.6z M12,24C5.4,24,0,18.6,0,12C0,5.4,5.4,0,12,0 s12,5.4,12,12C24,18.6,18.6,24,12,24z',
	compare       : 'M22.4,11.6C22.1,6,17.6,1.6,12,1.6c-2.8,0-5.4,1.1-7.3,3L4.4,5L6,6.6H1.2V1.9l2,2l0.3-0.3C5.8,1.2,8.8,0,12,0 c6.5,0,11.8,5.1,12,11.6H22.4z M12,24C5.5,24,0.2,18.8,0,12.4h1.6c0.2,5.6,4.8,10,10.4,10c2.8,0,5.4-1.1,7.3-3l0.3-0.3L18,17.4h4.7 v4.7l-2-2l-0.3,0.3C18.2,22.8,15.2,24,12,24z M11.2,19v-1.4l-0.3-0.1c-1.4-0.4-2-1.7-2.1-2.6h1.6c0.1,0.8,0.7,1.2,1.6,1.2 c1.1,0,1.7-0.6,1.7-1.7c0-0.9-0.5-1.1-2-1.8c-1.3-0.5-3-1.2-3-3.2c0-2.2,1.7-2.9,2.2-3.1l0.3-0.1V5h1.6v1.4l0.3,0.1 c1.4,0.4,2,1.7,2.1,2.6h-1.6c-0.1-0.8-0.7-1.2-1.6-1.2c-1.1,0-1.7,0.6-1.7,1.7c0,0.9,0.5,1.1,2,1.8c1.3,0.5,3,1.2,3,3.2 c0,1-0.6,2.6-2.2,3.1l-0.3,0.1V19H11.2z',
	why_compare   : 'M7.7,8.1h8.6V6.7H7.7V8.1z M7.7,12.9h5v-1.4h-5V12.9z M19.2,15h1.7V3.4H1.8C2,2.6,2.7,2,3.6,2h16.8 c1,0,1.9,0.9,1.9,1.9V15H24l-2.4,2.4L19.2,15z M7.7,17.6h8.6v-1.4H7.7L7.7,17.6z M3.6,22.3c-1.1,0-1.9-0.8-1.9-1.9V9.3H0l2.4-2.4 l2.4,2.4H3.1v11.6h19.1c-0.2,0.8-1,1.4-1.8,1.4H3.6z',
	finance       : 'M8.8,3.7h6.7c2.4,0,4.4,2,4.4,4.4v12.2c0,1.3-1.1,2.4-2.4,2.4H6.8c-1.3,0-2.4-1.1-2.4-2.4V8.1 C4.4,5.7,6.4,3.7,8.8,3.7z M6.1,2.4L6.1,2.4c0-0.6,0.4-1,1-1h10.1c0.5,0,1,0.4,1,1l0,0H6.1z M19.1,3.6c0.2-0.1,0.4-0.3,0.4-0.6V2.3 c0-1.3-1-2.3-2.3-2.3H7.1C5.9,0,4.8,1,4.8,2.3V3c0,0.3,0.2,0.5,0.4,0.6C3.9,4.7,3.1,6.4,3.1,8.1v12.2c0,2,1.7,3.7,3.7,3.7h10.8 c2,0,3.7-1.7,3.7-3.7V8.1C21.3,6.4,20.5,4.7,19.1,3.6z M13.4,17.3l5,3.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3 c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0-0.3-0.1l-0.1-0.1l0,0l-5-3.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0.1-0.3 c0.1-0.1,0.2-0.2,0.3-0.2C13.2,17.3,13.3,17.3,13.4,17.3z M8.7,20.8h6c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4h-6 c-0.2,0-0.4-0.2-0.4-0.4S8.5,20.8,8.7,20.8z M11.8,19.5h-6c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h6c0.2,0,0.4,0.2,0.4,0.4 C12.3,19.3,12.1,19.5,11.8,19.5z M14.5,20h-2c0.3-0.2,0.5-0.6,0.5-0.9L14.5,20z M5.8,20.2H8c-0.3,0.2-0.5,0.6-0.5,1 c0,0.7,0.5,1.2,1.2,1.2h6c0.7,0,1.2-0.5,1.2-1.2c0-0.1,0-0.2,0-0.3l1.6,1.1c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.8-0.2,1-0.6 c0.2-0.3,0.2-0.6,0.2-0.9c-0.1-0.3-0.3-0.6-0.5-0.8l-5.1-3.2c-0.3-0.2-0.6-0.2-0.9-0.2c-0.3,0.1-0.6,0.3-0.8,0.5 c-0.1,0.2-0.2,0.5-0.2,0.8h-0.1h-6c-0.7,0-1.2,0.5-1.2,1.2C4.6,19.7,5.2,20.2,5.8,20.2z M11.8,13.1v-1.8L11,11 c-0.5-0.1-0.9-0.6-0.9-1.1c0.1-0.9,0.8-1.7,1.7-1.8l0,0V7.6h0.8v0.5l0,0c0.9,0.1,1.6,0.8,1.7,1.7h-0.8c-0.1-0.5-0.4-0.9-0.9-0.9l0,0 v1.8l0.8,0.3c0.5,0.1,0.9,0.6,0.9,1.1c-0.1,0.9-0.8,1.7-1.7,1.8l0,0v0.5h-0.8v-0.5l0,0c-0.9-0.1-1.6-0.8-1.7-1.7h0.8 C10.9,12.6,11.3,13,11.8,13.1L11.8,13.1z M12.2,15.5c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5s-4.5,2-4.5,4.5S9.7,15.5,12.2,15.5z M13.5,12.1L13.5,12.1L13.5,12.1c0-0.2-0.2-0.4-0.4-0.4l-0.6-0.2v1.6l0,0C13.1,13,13.5,12.6,13.5,12.1 M11.8,8.8L11.8,8.8 c-0.5,0.1-0.9,0.5-0.9,1l0,0l0,0c0,0.2,0.2,0.4,0.4,0.4l0.6,0.2V8.8H11.8z',
	calculator    : 'M7.1,11.6h1.7V9.9H7.1L7.1,11.6z M11,11.6h1.7V9.9H11V11.6z M14.8,11.6h1.7V9.9h-1.7L14.8,11.6z M7.1,15.3h1.7 v-1.6H7.1V15.3z M11,15.3h1.7v-1.6H11V15.3z M14.8,15.3h1.7v-1.6h-1.7L14.8,15.3z M7.1,19h1.7v-1.6H7.1V19z M11,19h5.5v-1.6H11 L11,19z M3.7,5H20V1.6H3.7L3.7,5z M3.7,22.4H20V6.6H3.7L3.7,22.4z M4.1,24C3,24,2,23.1,2,21.9V2.1C2,0.9,3,0,4.1,0h15.4 c1.2,0,2.1,0.9,2.1,2.1v19.9c0,1.1-1,2.1-2.1,2.1H4.1z',
	arrow_down    : 'M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z',
	home          : 'M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z',
	clear         : 'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z',
	add           : 'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z',
	arrow_right   : 'M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z',
	arrow_left    : 'M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z',
	warning       : 'M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z',
	check         : 'M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z',
	translate     : 'M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z',
	notification  : 'M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z',
	data_usage    : 'M13 2.05v3.03c3.39.49 6 3.39 6 6.92 0 .9-.18 1.75-.48 2.54l2.6 1.53c.56-1.24.88-2.62.88-4.07 0-5.18-3.95-9.45-9-9.95zM12 19c-3.87 0-7-3.13-7-7 0-3.53 2.61-6.43 6-6.92V2.05c-5.06.5-9 4.76-9 9.95 0 5.52 4.47 10 9.99 10 3.31 0 6.24-1.61 8.06-4.09l-2.6-1.53C16.17 17.98 14.21 19 12 19z'
};

const Icon = props => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="icon">
    	<path d={icons[props.icon]}></path>
	</svg>
);

export default Icon;