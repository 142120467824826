import React from 'react'
import TopNav from './../navigation/TopNav'
import { FormattedMessage } from "react-intl"
import BottomNavigation from './../navigation/BottomNavigation'
import { Card, CardContent, CardAction } from './../utils/Card'
import { Link } from 'react-router-dom'
import google from 'react-ga';
google.initialize('UA-151343945-1');
google.pageview(window.location.pathname + window.location.search);

class Calculators extends React.Component {
    render() {
	    return (
	        <div className="screen-container">
	            <TopNav>
                    <h1><FormattedMessage id="topnav.calculators.h1" /></h1>
                </TopNav>
		        <div className="screen-content">
	            	<div className="container">
		            	<Card className={"card--white"}>
		            		<CardContent>
			            		<h3><FormattedMessage id="card1.calculators.h3" /></h3>
			            		<p><FormattedMessage id="card1.calculators.p" /></p>
		            		</CardContent>
		            		<CardAction>
			            		<Link
		                            to="/calculator/overtime">
		                            <FormattedMessage id="button.start" />
		                        </Link>
		            		</CardAction>
						</Card>
						<Card className={"card--white"}>
		            		<CardContent>
			            		<h3><FormattedMessage id="card2.calculators.h3" /></h3>
			            		<p><FormattedMessage id="card2.calculators.p" /></p>
		            		</CardContent>
		            		<CardAction>
			            		<Link
		                            to="/calculator/budget">
		                            <FormattedMessage id="button.start" />
		                        </Link>
		            		</CardAction>
						</Card>
		            </div>
	            </div>
	            <BottomNavigation />
	        </div>
	    );
	}
}

export default Calculators;