import React, { useContext } from 'react'
import { FormattedMessage, FormattedNumber } from "react-intl";
import PayPeriodSelect from '../form/PayPeriodSelect';
import { AppContext } from './../App'

export default function SummaryTotal(props) {
	const { currency } = useContext(AppContext)

	const {
		handlePayPeriod,
		payPeriod,
		totalAmount
	} = props

	function handleChange(name, payPeriod) {
		handlePayPeriod(payPeriod)
	}

	return (
		<React.Fragment>
			<div className="table-row legend-total-income">
	            <div className="column">
	                <span className="legend-table-icon" style={{backgroundColor: totalAmount.color, borderColor: totalAmount.color}}></span>
	                <FormattedMessage id={totalAmount.label} />
	            </div>
	            <div className="column">
	            	<span style={{color: totalAmount.color}}>
		            	<FormattedNumber
							value={totalAmount.amount}
							style="currency"
		    				currency={currency}
		    			/>
	            	</span>
	            </div>
			</div>
			<div className="table-row">
	            <div className="column"></div>
	            <div className="column">
	            	<PayPeriodSelect
	                    name="pay_period"
	                    value={payPeriod}
	                    onChange={handleChange}
					/>
	            </div>
	        </div>
		</React.Fragment>
	)
}