import React, { useState, useEffect, useContext, createContext } from 'react'
import { FormattedMessage } from "react-intl"
import TopNav from './../navigation/TopNav'
import TopHeader from './../navigation/TopHeader'
import Loader from './../utils/Loader'
import Logo from './../utils/Logo'
import BottomNavigation from './../navigation/BottomNavigation'
import CountryFromSelect from './CountryFromSelect'
import CountryToSelect from './CountryToSelect'
import AmountInput from './AmountInput'
import Button from './Button'
import { AppContext } from './../App'
import endpoint from '../../api'
import google from 'react-ga';
google.initialize('UA-151343945-1');
google.pageview(window.location.pathname + window.location.search);

export const CompareFormContext = createContext()

const COUNTRY_OPTIONS_KEY = 'saverasia.countryOptions'
const COUNTRY_FROM_KEY = 'saverasia.countryFrom'
const COUNTRY_TO_KEY = 'saverasia.countryTo'
const AMOUNT_KEY = 'saverasia.amount'

function Index() {
	const { selectedLanguage } = useContext(AppContext)

	const [countryOptions, setCountryOptions] = useState()
	const [countryToOptions, setCountryToOptions] = useState()
	const [countryFrom, setCountryFrom] = useState()
	const [countryTo, setCountryTo] = useState()
	const [amount, setAmount] = useState(0)
	const [currency, setCurrency] = useState()
	const [loading, setLoading] = useState(true)

	const compareFormContextValue = {
	    countryOptions,
		countryToOptions,
		countryFrom,
		countryTo,
		handleCountryFromChange,
		handleCountryToChange,
		handleAmountChange
	}

	useEffect(() => {
		endpoint.get(`/${selectedLanguage.value}/corridor/`).then(response => {
			localStorage.setItem(COUNTRY_OPTIONS_KEY, JSON.stringify(response.data))
			setInitialVariables(response.data)
			setLoading(false)
		})

		const storedAmount = localStorage.getItem(AMOUNT_KEY)
		if (storedAmount !== null) setAmount(parseInt(storedAmount))
	}, [selectedLanguage.value])

	useEffect(() => {
		if (!loading) {
			const defaultCountryFrom = countryOptions.find(() => true)
			const defaultCountryTo = defaultCountryFrom.list.find(() => true)

			const countryFromJSON = localStorage.getItem(COUNTRY_FROM_KEY)			
			if (countryFromJSON !== null) {
				setCountryFrom(JSON.parse(countryFromJSON))
			} else {
				setCountryFrom(defaultCountryFrom)
			}

			const countryToJSON = localStorage.getItem(COUNTRY_TO_KEY)
			if (countryToJSON !== null) {
				setCountryTo(JSON.parse(countryToJSON))
			} else {
				setCountryTo(defaultCountryTo)
			}
		}
	}, [loading])

	useEffect(() => {
		if (countryFrom !== undefined) {
			localStorage.setItem(COUNTRY_FROM_KEY, JSON.stringify(countryFrom))
			
			if (countryOptions !== undefined) {
				var options = countryOptions.filter(country => country.label == countryFrom.label)
				options = options.find(x => x != undefined)
				setCountryToOptions(options.list)
			}
		}
	}, [countryFrom])

	useEffect(() => {
		if (countryTo !== undefined) localStorage.setItem(COUNTRY_TO_KEY, JSON.stringify(countryTo))
	}, [countryTo])

	useEffect(() => {
		amount && localStorage.setItem(AMOUNT_KEY, amount)
	}, [amount])

	function setInitialVariables(response) {
		setCountryOptions(response)
		const option = response.find(() => true)
		setCountryToOptions(option.list)
		setCurrency(option.currency)
	}

	function handleCountryFromChange(fromCountry) {
		setCurrency(fromCountry.currency)
		setCountryFrom({ value: fromCountry.value, label: fromCountry.label, currency: fromCountry.currency, flag: fromCountry.flag })
		setCountryToOptions(fromCountry.list)
		setCountryTo(fromCountry.list.find(() => true))
	}

	function handleCountryToChange(toCountry) {
		setCountryTo(toCountry)
	}

	function handleAmountChange(newAmount) {
		setAmount(newAmount)
	}

	return (
		<div className="screen-container">
			<TopNav>
                <h1><FormattedMessage id="topnav.compare.form.h1" /></h1>
            </TopNav>
            <TopHeader>
                <h2><FormattedMessage id="topheader.compare.form.h2" /></h2>
                <p><FormattedMessage id="topheader.compare.form.p" /></p>
            </TopHeader>
            <div className="screen-content">
            	<div className="container">
					<CompareFormContext.Provider value={compareFormContextValue}>
						{loading && <Loader />}
						{!loading && <CountryFromSelect />}
						{!loading && <CountryToSelect />}            		
						{!loading && <AmountInput
										amount={amount}
										currency={currency}
									/>}
						{!loading && <Button amount={amount} />}
					</CompareFormContext.Provider>
					{!loading && 
						<Logo />
					}
				</div>
			</div>
			<BottomNavigation />
		</div>
	)
}

export default Index;
