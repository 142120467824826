import React, { useState, useEffect, useContext } from 'react';
import { BudgetContext } from './index'
import SummaryPieChart from './SummaryPieChart';
import Summary from './Summary';
import SummaryTotal from './SummaryTotal';
import { Card, CardContent } from '../utils/Card';

function SummaryList() {
	const { incomes, expenses, primaryIncome } = useContext(BudgetContext)

	const [payPeriod, setPayPeriod] = useState(primaryIncome.pay_period)

	const [totalIncomes, setTotalIncomes] = useState(() => {
		return {
			label: 'label.total_income',
			color: '',
			amount: getTotalByPayPeriod(incomes)
		}
	});

	const [totalExpenses, setTotalExpenses] = useState(() => {
		let amount = getTotalByPayPeriod(expenses)
		let color = '#00AB00'

		return {
			label: 'label.total_expense',
			color: color,
			amount: amount
		}
	});

	const [totalAmount, setTotalAmount] = useState(() => {
		let amount = parseFloat(totalIncomes.amount - totalExpenses.amount)
		let color = '#CC0000'
		
		if (Math.sign(amount) == 1) {
			color = '#00AB00'
			let changes = { color: '#CC0000' }
			setTotalExpenses({ ...totalExpenses, ...changes })			
		}

		return {
			label: 'label.money_left',
			color: color,
			amount: amount
		}
	});

	useEffect(() => {
		setTotalIncomes({...totalIncomes, amount: getTotalByPayPeriod(incomes) })
		setTotalExpenses({...totalExpenses, amount: getTotalByPayPeriod(expenses) })
		setTotalAmount({...totalAmount, amount: parseFloat(
				getTotalByPayPeriod(incomes) - getTotalByPayPeriod(expenses)
		)})
	}, [payPeriod])

	function getTotalByPayPeriod(items) {
		var total = 0

        items.map((item, index) => {
            total += parseFloat(item.amount / item.pay_period)
        })

        return parseFloat(total * payPeriod)
	}

	function handlePayPeriod(newPayPeriod) {
		setPayPeriod(newPayPeriod)
	}

	return (
		<Card className={"card--white"}>
            <SummaryPieChart
            	totalExpenses={totalExpenses}
            	totalAmount={totalAmount}
            />
            <CardContent>
            	<div className="summary-table">
	      			<Summary {...totalIncomes} />
	      			<Summary {...totalExpenses} />
	      			<SummaryTotal
	      				handlePayPeriod={handlePayPeriod}
	      				payPeriod={payPeriod}
	      				totalAmount={totalAmount}
	      			/>
	      		</div>
            </CardContent>
		</Card>
	)
}

export default SummaryList;
