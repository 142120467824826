import React from 'react';
import { FormattedMessage } from "react-intl";
import TopHeader from './../navigation/TopHeader';
import SummaryList from './SummaryList';

function OvertimeStep2() {
    return(
        <React.Fragment>
        	<TopHeader>
                <h2><FormattedMessage id="topheader.summary.h2" /></h2>
                <p><FormattedMessage id="topheader.summary.p" /></p>
            </TopHeader>
            <div className="screen-content">
                <div className="container">
                    <SummaryList />
                </div>
            </div>
        </React.Fragment>
    );
}

export default OvertimeStep2;